<template>
    <div>
        <div class="is-flex bg-slate-300 px-2 py-1"> 
            <span class="title is-size-5 mb-0"> Procese </span>
            <div class="ml-auto is-flex">
                <a @click.prevent="show_process_krudh()" class="page-title-btn" href="">
                    <i class="fa-solid fa-plus"></i> Procese
                </a>
            </div>
        </div>
        <div class="tile is-ancestor mt-0">
            <!-- BLOCK: Steps notifications -->
            <div v-if="selected_process_guid && processes_steps.length > 0">
                <div v-if="!transactionOriginOncePerStep" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                    <p class="font-bold">Transaksioni ne dalje</p>
                    <p>Nuk mund te lejohet me shume se nje here per proces</p>
                </div>

                <div v-if="!transactionDestinationOncePerStep" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                    <p class="font-bold">Transaksioni ne dalje</p>
                    <p>Nuk mund te lejohet me shume se nje here per proces</p>
                </div>


                <div v-if="Object.keys(sameServiceOncePerStep).filter(k => sameServiceOncePerStep[k] > 1).length > 0" 
                    class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                    <p class="font-bold">Sherbimi</p>
                    <p>I njejti sherbim nuk mund te perseritet per process</p>
                </div>

                <div v-if="!invoicableOncePerStep" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                    <p class="font-bold">Fatura</p>
                    <p>Nuk mund te krijoje fature per te njejtin proces</p>
                </div>
                
                <!-- not_allowed_combination --> 
                <div v-if="not_allowed_combination" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                    <p class="font-bold">Kombinim i pa lejuar</p>
                    <p>Origjina dhe destinacioni nuk jane te kombinueshme</p>
                </div>
            </div>
            <div  class="tile is-parent pb-0">
                <article class="tile is-child box px-2 py-0">
                    <div class="is-flex items-center">
                        <div class="py-2">
                            <div v-if="!selected_process.guid" class="select is-small">
                                <select @change="change_process($event)" v-model="selected_process_guid">
                                    <option :value="null">Zgjidh procesin</option>
                                    <option v-for="process in process_list" :key="process.guid" 
                                        :value="process.guid">{{process.name}}</option>
                                </select>
                            </div>
                            <div v-else>
                                <p class="subtitle mb-2 is-flex">
                                    <a @click.prevent="remove_selected_process()" class="remove_process_x" href="" >
                                        <i class="fa-solid fa-xmark"></i>
                                    </a>
                                    <span>
                                        {{selected_process.name}} 
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div v-if="selected_process.guid" class="ml-auto is-flex">
                            <div class="is-flex is-flex-direction-column border-l is-align-items-center px-2 pb-1 bg-slate-100 is-clickable justify-center" 
                                @click="save_destination_origin(selected_process, 'show_in_menu')"
                            >
                                <small class="text-xs font-bold">Ne menu</small>
                                <span class="mt-1">
                                    <i v-if="selected_process.show_in_menu" class="fa-regular fa-square-check"></i>
                                    <i v-else class="fa-regular fa-square fa-sm"></i>
                                </span>
                            </div>
                            <div class="is-flex is-flex-direction-column border-l px-2 pb-1 bg-slate-50 justify-center">
                                <small class="text-xs font-bold">
                                    Ikona
                                </small>
                                <process-icon :selected_process="selected_process"></process-icon>
                            </div>
                            <div class="is-flex is-flex-direction-column border-l px-2 pb-1 bg-slate-100 justify-center">
                                <small class="text-xs font-bold">Origjina</small>
                                <!-- <div :class="{'is-danger' : selected_process.destination_type == selected_process.origin_type}" -->
                                <div class="select is-small is-fullwidth">
                                    <select @change="save_destination_origin(selected_process, 'origin')" v-model="selected_process.origin_type">
                                        <option value=""> --- </option>
                                        <option :value="1">POS</option>
                                        <option :value="2">Magazine</option>
                                        <option :value="3">Furnitore</option>
                                        <option :value="4">Kliente</option>
                                    </select>
                                </div>
                                <div class="select is-small is-fullwidth" v-if="selected_process.origin_type == 2">
                                    <select v-model="selected_process.origin_type_facility" @change="save_destination_origin(selected_process, 'origin_type_facility', selected_process.origin_type_facility)">
                                      <option value="0">--- Te gjitha ---</option> 
                                      <option v-for="f in facility" :key="f.id" :value="f.id">{{f.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="is-flex is-flex-direction-column border-l px-2 pb-1 bg-slate-50 justify-center">
                                <small class="text-xs font-bold">Destinacioni</small>
                                <!-- <div :class="{'is-danger' : selected_process.destination_type == selected_process.origin_type}" -->
                                <div :class="{'is-danger' : not_allowed_combination}"
                                    class="select is-small is-fullwidth ">
                                    <select @change="save_destination_origin(selected_process, 'destination')" v-model="selected_process.destination_type">
                                        <option value=""> --- </option>
                                        <option :value="1">POS</option>
                                        <option :value="2">Magazine</option>
                                        <option :value="3">Furnitore</option>
                                        <option :value="4">Kliente</option>
                                    </select>
                                </div>
                                <div v-if="selected_process.destination_type == 2">
                                    <select class="select is-small is-fullwidth" v-model="selected_process.destination_type_facility" @change="save_destination_origin(selected_process, 'destination_type_facility', selected_process.destination_type_facility)">
                                      <option value="0">--- Te gjitha ---</option> 
                                      <option v-for="f in facility" :key="f.id" :value="f.id">{{f.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div  class="is-flex is-align-items-center border-l pl-2 pr-2">
                                <a @click.prevent="show_steps_krudh(selected_process)" class="button is-small" href="">
                                <i class="fa fa-cog mr-2"></i> Hapa
                                </a>
                            </div>
                            <!-- preview process --> 
                            <div class="is-flex is-align-items-center border-l pl-2 pr-2">
                                <a :href="'/process/' + selected_process.guid + '/new'" class="button is-small" target="_blank">
                                    <i class="fa fa-eye mr-2"></i> Shiko
                                </a>
                            </div>

                        </div>
                    </div>
                </article>
            </div>
        </div>

        <!-- Steps -->
        <div v-if="selected_process.guid" class="relative">
            <div @click="show_change_sort = !show_change_sort" v-if="sorted_steps.length > 0" class="absolute right-1 top-1 px-2 py-1 rounded is-clickable is-flex is-flex-direction-column is-align-items-center" 
                style="z-index: 999; box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; background: #fff;" title="Ndrysho renditjen">
                <i class="fa-solid fa-table-cells-large"></i>
                <span style="font-size:9px;">Organizo</span>
            </div>
            <div v-if="loading_steps" class="is-flex is-justify-content-center my-3">
                <i class="fa fa-spinner fa-spin mr-2"></i>
            </div>
            <div v-if="selected_process.guid && !loading_steps && processes_steps.length == 0" class="is-flex is-justify-content-center mt-5">
                <a @click.prevent="show_steps_krudh(selected_process)" class="button is-small" href="">
                    Krijo nje hap te ri
                </a>
            </div>
            <!-- Steps grid - Sortable -->
            <div class="">

                <div v-if="show_change_sort">
                    <div v-if="sort_has_changed" class=" bg-gradient-to-r from-transparent via-green-200 to-transparent is-flex is-justify-content-center my-3 p-2">
                        <a @click.prevent="save_steps_sort(processes_steps)" href="" class="button is-small">
                            Ruaj ndryshimin e renditjes
                        </a>
                    </div>
                    <div v-if="!sort_has_changed" class="mb-3" style="">
                        <span class="p-2 rounded" style="z-index: 999; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; background: rgb(255, 255, 255);">
                            <i class="fa-solid fa-circle-info mr-2"></i> Ndrysho rradhen duke terhequr nje hap.
                        </span>
                    </div>
                    <draggable class="dragArea  grid grid-cols-5 gap-2 auto-cols-fr" 
                        :list="sorted_steps" 
                        @change="log" ghost-class="ghost">
                        <step-content :stepGeneralStatuses="stepGeneralStatuses"
                            :sorted_steps="sorted_steps"
                            :selected_process="selected_process"
                            :service_types="service_types"
                            :creditnote_types="creditnote_types"
                        ></step-content>
                    </draggable>
                </div>
                <div v-else>
                    <div class="grid grid-cols-5 gap-2 auto-cols-fr">
                        <step-content :stepGeneralStatuses="stepGeneralStatuses"
                            :sorted_steps="sorted_steps"
                            :selected_process="selected_process"
                            :service_types="service_types"
                            :creditnote_types="creditnote_types"
                            :process_types="process_types"
                        ></step-content>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import ProcessServices from "@/services/Processes";
import WarehousesServices from "@/services/Warehouse";
import ServiceServices from "@/services/Service";
import { $vfm } from 'vue-final-modal'
import { defineAsyncComponent } from "vue"
import Api from "@/services/Api";
import { useKrudhStore } from "@/stores/krudh_communication";
import { storeToRefs } from "pinia";
import { VueDraggableNext } from 'vue-draggable-next'
import stepContent from './components/step-content.vue'
import processIcon from "./components/process-icon.vue";
export default {
    components: {
        draggable: VueDraggableNext, stepContent , processIcon
    },
    data() {
        return {
            process_list: [],
            processes_steps: [],
            selected_process_guid: null,
            selected_process: {},
            krudh_communication_data: storeToRefs(useKrudhStore()),
            loading_steps: false,
            dragging: false,
            process_list_original: [],
            sort_has_changed: false,
            service_types: [],
            show_change_sort: false,
            facility: [],
            creditnote_types: [],
            process_types: []

        }
    },
    methods: {
        async get_initial_data(){
            this.facility = await WarehousesServices.getWarehouseFacilities()
            await Api(true).post("creditnote/get")
            .then(res => {
                this.creditnote_types = res.data.types
            })
            await Api(true).post("processes/erp/process_types")
            .then(res => {
                this.process_types = res.data
            })
        },
        async change_process(event){
            this.selected_process = {...this.process_list.find(p => p.guid == event.target.value)}
            this.loading_steps = true
            this.processes_steps = await ProcessServices.getProcessesSteps(this.selected_process.guid)
            // Mandates list
            

            this.loading_steps = false
        },
        show_process_krudh(entity) {
            console.log(entity)
            var params = {
                fields: [
                    'name:Titulli i procesit,sort_nr:Renditja'
                    // 'icon:Ikona,*name:Emri', 
                    // 'description:Pershkrimi'
                ],
                entity: 'processes',
                columns: ['icon:80px', 'name:130px'],
                title: 'Procese'
            }
            $vfm.show({
                component: defineAsyncComponent(
                    () => import("@/components/krudh-modal.vue")
                ), params
            })
        },
        show_steps_krudh(entity) {
            var params = {
                fields: [
                    'name:Titulli i hapit',
                    'description:Pershkrimi',
                    'subdivide_documents: Ndan dokumente:checkbox-field'
                    // 'icon:Ikona,*name:Emri', 
                ],
                entity: 'process_steps',
                columns: ['icon:80px', 'name:130px'],
                title: 'Hapat e proceseve',
                where: [
                    { field: 'process_id', operation: '=', value: entity.guid }
                ],
                defaults: [
                    {
                        field: 'process_id', value: entity.guid
                    }
                ]
            }
            $vfm.show({
                component: defineAsyncComponent(
                    () => import("@/components/krudh-modal.vue")
                ), params
            })
        },
        show_step_notification_krudh(step){
            var params = {
                fields: [
                    'code:Kodi',
                    'name:Titulli',
                    'description:Pershkrimi',
                    'needs_confirmation: Pret konfirmim:checkbox-field',
                    'is_required: Eshte i detyrueshem?:checkbox-field'
                    // 'subdivide_documents: Ndan dokumente:checkbox-field'
                    // 'icon:Ikona,*name:Emri', 
                ],
                entity: 'process_steps_disputes',
                allow_delete: true,
                // columns: ['icon:80px', 'name:130px'],
                title: 'Disputes',
                where: [
                    { field: 'step_id', operation: '=', value: step.guid }
                ],
                defaults: [
                    {
                        field: 'step_id', value: step.guid
                    }
                ]
            }
            $vfm.show({
                component: defineAsyncComponent(
                    () => import("@/components/krudh-modal.vue")
                ), params
            })
        },
        remove_selected_process(){
            this.processes_steps = [],
            this.selected_process_guid = null,
            this.selected_process = {}
        },
        save_dispute(d){
            // Set the changed value
            !d.needs_confirmation ? d.needs_confirmation = 1 : d.needs_confirmation = 0

            Api(true).post("processes/save/disputes", {dispute: d})
            .then(r => {
                if(r.data[0].guid){
                    this.$toast.success('Ndryshimi u ruajt me sukses.');
                }
            })
        },
        log(event) {
            this.processes_steps = [...this.processes_steps.map((s, index) => {
                if( (event.moved.oldIndex > event.moved.newIndex) && (event.moved.newIndex <= index && index < event.moved.oldIndex)){
                    s.sort_nr += 1
                }
                else if( (event.moved.oldIndex < event.moved.newIndex) && (event.moved.newIndex >= index && index > event.moved.oldIndex)){
                    s.sort_nr -= 1
                }
                if(s.guid == event.moved.element.guid){
                    s.sort_nr = event.moved.newIndex
                }

                this.sort_has_changed = true

                // for (let i = 0; i < this.process_list_original.length; i++) {
                //     if( this.process_list_original[i].sort_nr != s.sort_nr){
                //         this.sort_has_changed = true
                //     }
                // }

                return {...s}
            })]
        },
        save_steps_sort(aa){
            Api(true).post("processes/save/steps/sort", {list: aa})
            .then(() => {
                this.sort_has_changed = false
                this.process_list_original = [...this.process_list]
                this.show_change_sort = false
                this.$toast.success('Ndryshimi i renditjes u ruajt me sukses.')
            })
        },
        // revert_sort(){
        //     setTimeout(() => {
        //         this.process_list = this.process_list_original
        //     }, 100);
        // }
        save_destination_origin(process, party, value = null){
            if(party == 'show_in_menu'){
                if(!process.show_in_menu)
                    process.show_in_menu = 1
                else
                    process.show_in_menu = 0
            }
            Api(true).post("processes/save/party", {
                process: process,
                party: party,
                value
            }).then(res => {
                if(res.data == 1)
                    this.$toast.success('Ndryshimi i renditjes u ruajt me sukses.')

                if(party == 'icon'){
                    //close icon 
                }
            })

        },
        async save_step(step){
            // console.log(
            //     'this.transactionOriginOncePerStep', this.transactionOriginOncePerStep,
            //     'this.transactionDestinationOncePerStep', this.transactionDestinationOncePerStep,
            //     // 'aaa', Object.keys(this.sameServiceOncePerStep).filter(k => this.sameServiceOncePerStep[k] > 1).length > 0

            // )
            if(this.transactionOriginOncePerStep 
                || this.transactionDestinationOncePerStep 
                || this.invoicableOncePerStep 
                || (Object.keys(this.sameServiceOncePerStep).filter(k => this.sameServiceOncePerStep[k] > 1).length > 0)
            ){
                await Api(true).post("processes/save/step/config", { step })
            }
            else{
                this.$toast.error('Ju lutem kontrolloni konfigurimin e procesit')
            }

        }
    },
    watch: {
        krudh_communication_data: {
            async handler(n) {
                if(n.entity == 'processes'){
                    // Update process_list
                    this.process_list = await ProcessServices.getProcesses()
                }
                else if(n.entity == 'process_steps' || n.entity == 'process_steps_disputes'){
                    //processes_steps
                    this.processes_steps = await ProcessServices.getProcessesSteps(this.selected_process.guid)
                }
                // Reset store variables
                // BUG: shouldnt resset the variables
                // Close Modal - Temporary solution


                // this.krudh_communication_data.response = [],
                // this.krudh_communication_data.entity = '',
                // this.krudh_communication_data.is_new = false
            },
            deep: true
        }
    },
    computed: {
        not_allowed_combination(){
            var not_allowed = (this.selected_process.destination_type == this.selected_process.origin_type && this.selected_process.destination_type == 1) || 
              (this.selected_process.destination_type == 3 && this.selected_process.origin_type == 1); 
            
            return not_allowed
        },
        sorted_steps(){
            var list = []
            list = this.processes_steps
            return list.sort((a , b) => a.sort_nr - b.sort_nr)
        },
        transactionOriginOncePerStep(){
            var list = []
            list = this.processes_steps
            return list.filter(st => st.is_transaction == 1)?.length <= 1
        },

        transactionDestinationOncePerStep(){
            var list = []
            list = this.processes_steps
            return list.filter(st => st.is_transaction == 2)?.length <= 1
        }, 

        sameServiceOncePerStep(){ 
            var list = []
            list = this.processes_steps
            var services = {}
            list.map(st => {
                if(st.service_id){
                    if(services[st.service_id]){
                        services[st.service_id] = services[st.service_id] + 1
                    }
                    else{
                        services[st.service_id] = 1
                    }
                }
            })
            return services
        }, 

        invoicableOncePerStep(){
            var list = []
            list = this.processes_steps
            return list.filter(st => st.creates_invoices == 1)?.length <= 1
        },
        destination_client_one_invoice(){
            if(this.selected_process.destination_type == 4){
                var count = 0
                this.processes_steps.forEach(step => {
                    if(step.creates_invoices == 1){
                        count ++
                    }
                });
                if(count > 0)
                    return false
            }
            return true
        },
        origin_transaction_check(){
            if(this.selected_process.origin_type == 2 || this.selected_process.origin_type == 1){
                var count_for_origin = 0
                var count_for_destination = 0
                var origin_steps = []
                var destination_steps = []
                this.processes_steps.forEach(step => {
                    if(step.is_transaction == 1) {
                        count_for_origin++
                        origin_steps.push(step.guid)
                    } 
                    else if(step.is_transaction == 2) {
                        count_for_destination++
                        destination_steps.push(step.guid)
                    } 
                });
                if(count_for_origin > 0 || count_for_destination > 0)
                    return {
                        value: false,
                        origin: count_for_origin,
                        destination: count_for_destination,
                        origin_steps,
                        destination_steps
                        
                    }
            }
            return {value: true}
        },

        stepGeneralStatuses(){
            return {
                'origin_transaction_once': this.transactionOriginOncePerStep,
                'destination_transaction_once': this.transactionDestinationOncePerStep,
                'same_service_once': this.sameServiceOncePerStep,
                'invoicable_once': this.invoicableOncePerStep,
                'destination_client_one_invoice' : this.destination_client_one_invoice,
                'origin_transaction_check': this.origin_transaction_check
            }
        }
       
    },
    async created(){
        this.process_list = await ProcessServices.getProcesses()
        this.service_types = await ServiceServices.getServiceTypes()
        this.process_list_original = this.process_list
        await this.get_initial_data()
    }

}
</script>

<style scoped>
    .page-title-btn{
        border:solid 1px #dedede;
        padding:2px 8px;
        font-size: 12px;
    }
    .remove_process_x{
        font-size:10px;
        color:red;
        padding-right:5px;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
    .ghost{
        border:solid 1px #cbd5e1!important;
    }
    .is-error{
        border:solid 1px;
    }
</style>
