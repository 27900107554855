<template>
    <div>
        <div class="relative">
            <div @click.prevent=" is_visible = is_visible == 1 ? 0 : 1 " class="" >
                <i :class="[props.icon_class ? props.icon_class : 'text-sky-200']"
                    class="fa-regular fa-circle-question is-clickable"></i> 
            </div>
            <div
            v-if="is_visible"
            class="absolute right-0 top-5 bg-white p-0 border rounded"
            style="
                min-width: 300px;
                z-index: 999;
                box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
            "
            id=""
            role=""
            >
            <div class="is-flex is-align-items-center border-b px-2 py-1 bg-sky-100" >
                <span class="font-semibold">Info </span>
                <button
                    @click.prevent="is_visible = 0"
                    class="delete ml-auto is-small"
                ></button>
            </div>
            <p v-if="is_visible"  class="px-2 py-1">
                {{props.content}} 
            </p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref } from "vue";
    const props = defineProps([
        "content", 
        "icon_class"
    ]);

    var is_visible = ref(false)
</script>